import Vue from 'vue'
import axios from "axios";//原生的axios

//用来拦截用的
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
let serveId = localStorage.getItem("serveId");

  // let configs = JSON.parse(localStorage.getItem('config'));
  // let serveId = configs ? configs.service_id : null
  let finger = localStorage.getItem('finger-id')
//创建一个单例
const http= axios.create({
  // baseURL:'http://www.test91cq.com',
  // baseURL:'https://betaoa.3pk.com',
  baseURL:'https://api.91cq.com',
  // baseURL:'http://220.250.19.202:18005',
  timeout:10000,//响应时间
  headers: {
    "apikey": 'bst_1f3c5d66-6cca-0452-8119-1f6c16c6e680',
    "client-type":'PC',
    "finger-id" : '1',
    // "service-id": serveId ? serveId : '',
  }
  
  // headers:{"Content-Type":"application/json;charset=utf-8"},
})
const CancelToken = axios.CancelToken

 
//拦截器  -请求拦截
http.interceptors.request.use(config=>{
  //部分接口需要token
  let token=localStorage.getItem('token');
  let ydtoken = localStorage.getItem("ydtoken")
  console.log('我进入了请求拦截，我即将为请求头赋值',serveId)
  if(serveId){
    config.headers['service-id'] = serveId 
    console.log('我是请求拦截，我拿到serverID',serveId)
  }
  if(ydtoken){
    // console.log('我进入了ydtoken')
    config.headers['ydtoken'] = ydtoken
  }
  // if(finger){
  //   config.headers['finger-id'] = finger
  // }
  if(token){
    // config.headers.token=token;
    config.headers.Authorization = 'Bearer ' + token
  }
  // 强行中断请求要用到的，记录请求信息
  config['cancelToken'] = new CancelToken(function executor(cancel) {
    Vue.$httpRequestList.push(cancel) //存储cancle
  })
  return config;
},err=>{
  return Promise.reject(err)
})
 
//拦截器  -响应拦截
http.interceptors.response.use(res=>{
    if(res.data.code == 200){
      if(res.headers['service-id'] != undefined ) {
          serveId = res.headers['service-id'];
          localStorage.setItem("serveId",serveId);
      }
    return Promise.resolve(res.data,res)
    //这里读者们可以根据服务器返回的数据去自行修改
  }else{
    console.log('我是响应拦截',res)
    return Promise.reject(res.data)
  }
},err=>{
    if(err.message === 'interrupt') {
        console.log('请求中断');
        return new Promise(() => {});
      }
    
  return Promise.reject(err)
});

 
//整体导出
export default http;
 