<template>
    <!-- 登录弹窗 -->
    <div class="login_body" v-if="isWindow">
        <div class="login_box">
            <img :src="img_url + material.pc_login_bj" alt="">
            <img class="gb" src="/login/gb.png" @click="colseLogin" alt="">
            <div class="login_content">
                <img class="login_title" src="/login/login_title.png" alt="">
                <div class="login_center">
                    <div class="login_input">
                        <div class="input_title" v-if="(getClient.channel_other.is_login_mobile == 1 ? false : true)&&(getClient.channel_other.is_login_account == 1 ? true : false)">
                            手机号登录
                            <div>玛法大陆冯纷争再起</div>
                            <div class="user">
                                <label>手机号</label>
                                <div class="input_fgx"></div>
                                <input v-model="user_mobile">
                            </div>
                            <div class="user">
                                <label >验证码</label>
                                <div class="input_fgx"></div>
                                <input v-model="code" type="tel" style="width: 60%">
                                <span class="yzm_font flex_box" @click="getCode" v-if="is_login_code">获取验证码</span>
                                <span class="yzm_font flex_box" v-else>{{ count }}秒后重发</span>
                            </div>
                            <div class="login_btn">
                                <a class=" form_btn" :class="{'btn_width':getClient.channel_other.is_register == 1 ? true : false}" @click="mobileLogin">登入</a>
                            </div>
                        </div>
                        <div class="input_title" v-if="getClient.channel_other.is_login_account == 1 ? false : true">
                            账号登录注册
                            <div>玛法大陆冯纷争再起</div>
                            <div class="user">
                                <label>账 号</label>
                                <div class="input_fgx"></div>
                                <input v-model="loginForm.account">
                            </div>
                            <div class="user">
                                <label>密 码</label>
                                <div class="input_fgx"></div>
                                <input v-model="loginForm.password" type="password">
                            </div>
                            <div class="login_btn">
                                <a class=" form_btn" :class="{'btn_width':getClient.channel_other.is_register == 1 ? true : false}" @click="logining">登入</a>
                                <a class=" form_btn" v-if="getClient.channel_other.is_register == 1 ? false : true" @click="zhuce">注 册</a>
                            </div>

                        </div>
                        <img style="margin: 0 8% 0 3%;" src="/login/fenge.png" v-if="(getClient.channel_other.is_login_weixin == 1) || (getClient.channel_other.is_login_account == 1) &&  (getClient.channel_other.is_login_mobile == 1)? false : true">
                        <div class="input_title right" v-if="getClient.channel_other.is_login_weixin == 1 ? false : true">
                            微信账号登录
                            <div>沙城争霸再续前缘</div>
                            <div class="wx_login">
                                <div id="qrCode" ref="qrCodeDiv"></div>
                                <!-- 遮罩 -->
                                <div class="zz2" ref="zz2" v-if="isCode">
                                    <div class="zz_title flex_box">
                                        <span>二维码已过期</span>
                                        <span @click="refreshCode" class="plus">点击刷新二维码</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="host_paw" :style="{'justify-content':getClient.channel_other.is_login_account == 1 ? 'center' : 'space-between'}">
                        <div class="remember flex_box" @click="play(isPlay)" v-if="getClient.channel_other.is_login_account == 1 ? false : true">
                            <div class="gou flex_box">
                                <img class="dagou" v-show="isPlay" src="/header/img/gou.png">
                            </div>
                            <a class="paw_title">记住密码</a>
                        </div>
                        <div class="usercl">登录即代表你同意<a @click="toAgreement">用户协议</a>和<a @click="toPrivacy">隐私政策</a>
                        </div>
                        <div class="forget" v-if="getClient.channel_other.is_login_account == 1 ? false : true">
                            <a class="paw_title" @click="closeTc">忘记密码？</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
   
<script>
import QRCode from 'qrcodejs2';
import { mapState } from 'vuex';
import { loginStatusPolling, accountLogin, loginCode,getUserInformation,messageVerification,mobileLogin } from '@/api/http.js'
// 引入加密
const Base64 = require('js-base64').Base64
export default {
    name: 'Login',
    data() {
        return {
            isWindow: false,
            ewmUrl: '',
            loginForm: {},
            seconds: 600, // 倒计时秒数,
            loginToken: null,
            isCode: false,
            timeOut: null,  // 定时器,
            isPlay: false,
            code: '',
            url:'/bank',
            user_mobile:'',
            is_login_code: true, //判断验证码按钮
            count:'',
            sms_token:''
        }
    },
    computed: {
        ...mapState([
            'getClient',
            'material'
        ]),
        isDialog: {
            get() {
                return this.$store.state.isDialog
            },
            set(val) {
                return this.$store.state.isDialog = val
            }
        },
    },
    created() {

    },
    methods: {
        getCode() {
            this.messageVerification()
            const num = 60;
            if (!this.timer) {
                this.count = num;
                this.is_login_code = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= num) {
                        this.count--;
                    } else {
                        this.is_login_code = true;
                        clearInterval(this.timer);
                        this.timer = null;
                        this.bool = true
                    }
                }, 1000);
            }
        },
        //短信验证
        messageVerification() {
            messageVerification({
                mobile: this.user_mobile,
                scene: 'login_mobile'
            }).then((res) => {
                if (res.code === 200) {
                    this.is_login_code = false
                    this.sms_token = res.data.sms_token
                    this.$message({
                        message: '短信发送成功！',
                        type: 'success',
                        duration: 3000
                    })
                }
            }).catch((err) => {
                this.is_login_code = true
                clearInterval(this.timer);
                this.timer = null;
                console.log('捕获异常', err)
                this.is_login_code = true
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500
                })
            })
        },
        toAgreement() {
            let routeUrl = this.$router.resolve({
                path: this.url,
                query: {
                    id: this.getClient.customer.member_agreement
                }
            })
            window.open(routeUrl.href, '_blank');
        },
        toPrivacy() {
            let routeUrl = this.$router.resolve({
                path: this.url,
                query: {
                    id: this.getClient.customer.member_privacy
                }
            })
            window.open(routeUrl.href, '_blank');
        },
        open() {
            // 在页面加载时从cookie获取登录信息
            let account = this.getCookie("account")
            let password = Base64.decode(this.getCookie("password"))
            // 如果存在赋值给表单，并且将记住密码勾选
            if (account) {
                this.loginForm.account = account
                this.loginForm.password = password
                this.isPlay = true
            }
            this.isDialog = true
            /* 获取二维码并且轮询 */
            this.isWindow = true
            //初始化 易盾
            // var config = {
            //     appId: 'YD00042460129722',
            //     timeout: 500
            // };
            // const neg = new createNEGuardian(config);
            // neg.getToken().then(function (data) {
            //     if (data.code == 200) {
            //         const stokens = data.token || ''
            //         localStorage.setItem('ydtoken',stokens)
            //     }
               
            // }).catch((err) =>{
            //     console.log('捕获异常', err)
            // }).finally(()=>{
            //      //二维码请求
            //      this.loginCode()
            // })
            // //二维码请求
            this.loginCode()
            this.Time()
        },
        /* 关闭窗口 */
        colseLogin() {
            /* 清除定时器\表单\扫码token */
            this.loginForm = {}
            this.loginToken = null
            this.isWindow = false
            this.isDialog = false
            this.$emit('closeLogin')
            clearInterval(this.timeOut)
        },
        // 手机号登录
        mobileLogin(){
            mobileLogin({
                mobile:this.user_mobile,
                code:this.code,
                sms_token:this.sms_token
            }).then((res) => {
                if (res.code === 200) {
                    localStorage.setItem('inquiry_show', 1) //控制询问弹窗显示·
                    localStorage.setItem("token", res.data.token)
                    const is_auth = res.data.is_auth
                    // this.setCookie('token', res.data.token)
                    // 储存登录信息
                    this.setUserInfo()
                    //一定要请求一次用户信息，否则以下路由会报错
                    getUserInformation().then((res) => {
                        if (res.code === 200) {
                            localStorage.setItem('userInfo', JSON.stringify(res.data))
                            this.userInfo = res.data
                            this.unLogin = false
                            this.isLogin = true
                            // 当本地存储有userinfo后再判断实名跳转
                            if(!this.userInfo.user_mobile){
                                this.$router.push({ path: "/member/phoneBinding" })
                                window.location.href=window.location.href
                            }
                            else {
                                this.$router.push({ path: "/home" })
                                window.location.href=window.location.href
                            } 
                            // else {
                            //     console.log('我进入了else要去实名')
                            //     this.$router.push({
                            //         path: '/member/realName'
                            //     })
                            //     window.location.href=window.location.href
                            // }
                        }
                    }).catch((err) => {
                        //token失效时清除本都数据
                        localStorage.setItem("token", '')
                        this.setCookie('token', '')
                        this.$router.push({
                            path: '/home'
                        })
                        console.log('捕获异常', err)
                    })
                }
            }).catch((err) => {
                console.log("捕获异常", err)
                this.bool = true
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                });
            })
        },
        // 账号登录按钮事件
        logining() {
            accountLogin(this.loginForm).then((res) => {
                if (res.code === 200) {
                    localStorage.setItem('inquiry_show', 1) //控制询问弹窗显示·
                    localStorage.setItem("token", res.data.token)
                    const is_auth = res.data.is_auth
                  
                    //一定要请求一次用户信息，否则以下路由会报错
                    getUserInformation().then((res) => {
                        if (res.code === 200) {
                            localStorage.setItem('userInfo', JSON.stringify(res.data))
                            this.userInfo = res.data
                            this.unLogin = false
                            this.isLogin = true
                            // 当本地存储有userinfo后再判断实名跳转
                            if(!this.userInfo.user_mobile){
                                this.$router.push({ path: "/member/phoneBinding" })
                                window.location.href=window.location.href
                            }
                            else {
                                this.$router.push({ path: "/home" })
                                window.location.href=window.location.href
                            } 
                            // else {
                            //     console.log('我进入了else要去实名')
                            //     this.$router.push({
                            //         path: '/member/realName'
                            //     })
                            //     window.location.href=window.location.href
                            // }
                        }
                    }).catch((err) => {
                        //token失效时清除本都数据
                        localStorage.setItem("token", '')
                        this.setCookie('token', '')
                        this.$router.push({
                            path: '/home'
                        })
                        console.log('捕获异常', err)
                    })
                }
            }).catch((err) => {
                console.log("捕获异常", err)
                this.bool = true
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                });
            })
        },

        // 获取二维码接口
        loginCode() {
            // this.$refs.zz2.style.display = "none"; //隐藏遮罩
            this.seconds = 600 //初始化倒计时
            $('#qrCode').html('');
            loginCode().then((res) => {
                if (res.code === 200) {
                    this.loginToken = res.data.token
                    new QRCode(this.$refs.qrCodeDiv, {
                        text: res.data.url,
                        width: 143,
                        height: 143,
                        colorDark: "#333333", //二维码颜色
                        colorLight: "#ffffff", //二维码背景色
                        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
                    })
                } else {
                    console.log(res.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        },

        //定时器没过1秒参数减1
        Time() {
            this.timeOut = setInterval(() => {
                this.seconds -= 1
                this.loginStatusPolling()
                if (this.seconds == 0) {
                    clearInterval(this.timeOut)
                    this.isCode = true
                }
            }, 1000)
        },
        refreshCode() {
            this.loginCode()
            this.Time()
            this.isCode = false
        },
        //二维码轮巡
        loginStatusPolling() {
            loginStatusPolling({
                token: this.loginToken
            }).then((res) => {
                if (res.code === 200) {
                    localStorage.setItem('token', res.data.token)
                    localStorage.setItem('inquiry_show', 1) //控制询问弹窗显示·
                    clearInterval(this.timer)
                    this.timer = ''
                    const is_auth = res.data.is_auth
                    //一定要请求一次用户信息，否则以下路由会报错
                    getUserInformation().then((res) => {
                        if (res.code === 200) {
                            localStorage.setItem('userInfo', JSON.stringify(res.data))
                            this.userInfo = res.data
                            this.unLogin = false
                            this.isLogin = true
                            // 当本地存储有userinfo后再判断实名跳转
                            if(!this.userInfo.user_mobile){
                                // this.$router.push({ path: "/member/phoneBinding" })
                                window.location.href=window.location.href
                            }
                            else{
                                this.$router.push({ path: "/home" })
                                window.location.href=window.location.href
                            } 
                            // else {
                            //     console.log('我进入了else要去实名')
                            //     this.$router.push({
                            //         path: '/member/realName'
                            //     })
                            //     window.location.href=window.location.href
                            // }
                        } else {
                            console.log(res.msg)
                        }
                    }).catch((err) => {
                        //token失效时清除本都数据
                        localStorage.setItem("token", '')
                        this.setCookie('token', '')
                        this.$router.push({
                            path: '/home'
                        })
                        console.log('捕获异常', err)
                    })
                }
            }).catch((err) => {
                if (err.code === 20001) {
                    this.$router.push({
                        name: 'registration',
                        query: {
                            tokens: err.msg,
                        }
                    })
                    clearInterval(this.timeOut)
                    window.location.href = window.location.href
                }
                else {
                    console.log(err.msg)
                }
            })

        },
        zhuce() {
            this.colseLogin()
            this.$router.push({
                name: 'registration',
            })
        },
        // 储存表单信息
        setUserInfo() {
            // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
            // 如果没有勾选，储存的信息为空
            if (this.isPlay) {
                this.setCookie("account", this.loginForm.account)
                // base64加密密码
                let passWord = Base64.encode(this.loginForm.password)
                this.setCookie("password", passWord)
            } else {
                this.setCookie("account", "")
                this.setCookie("password", "")
            }
        },

        // 获取cookie
        getCookie(key) {
            if (document.cookie.length > 0) {
                var start = document.cookie.indexOf(key + '=')
                if (start !== -1) {
                    start = start + key.length + 1
                    var end = document.cookie.indexOf(';', start)
                    if (end === -1) end = document.cookie.length
                    return unescape(document.cookie.substring(start, end))
                }
            }
            return ''
        },
        // 保存cookie
        setCookie(cName, value, expiredays) {
            var exdate = new Date()
            exdate.setDate(exdate.getDate() + expiredays)
            document.cookie = cName + '=' + decodeURIComponent(value) +
                ((expiredays == null) ? '' : ';expires=' + exdate.toGMTString())
        },

        //跳转到修改密码
        closeTc() {
            this.$router.push({ path: '/forgot' })
            location.reload()
        },
        // 跳转到注册页面
        toRegistration() {
            this.$router.push({ path: '/register' })
            location.reload()
        },
        play(isPlay) {
            if (isPlay) {
                this.isPlay = false
            } else {
                this.isPlay = true
            }
        },



    }
}
</script>
   
<style lang="less" scoped>
body {
    z-index: 0;
}
.yzm_font {
    width: 100px;
    height: 40px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #E7C790;
    border: 1px solid #6C5C3F;
    border-left: none;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    background: #29221C;
    margin-top: 10px;
    cursor: pointer;
}
.login_body {
    position: fixed;
    z-index: 999 !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    overflow-x: hidden;
}

.login_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 2%;
}

.login_box>img {
    width: 83rem;
}

.gb {
    position: absolute;
    right: 13%;
    width: 2.5% !important;
    top: 32%;
    cursor: pointer;
    z-index: 999;
}

.login_content {
    position: absolute;
    top: 30.5%;
    width: 50%;
    height: 48%;
    // background-color: red;
    left: 36%;
}
.login_center{
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.login_input {
    display: flex;
    justify-content: center;
}

.login_title {
    margin-left: 33%;
    margin-top: 3%;
    width: 14rem;
    margin-bottom: 2%;
}

.input_title {
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #F6CF8B;
    text-align: center;

    div {
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #83755D;
    }
}

.user {
    display: flex;
    align-items: center;
    position: relative;
    left: -5%;
    margin-bottom: 12px;

}

.user label {
    position: relative;
    left: 40px;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #F6CF8B;
    top: 5px;
    width: 33px;
    white-space: nowrap;
}

.user input {
    margin-top: 10px;
    width: 300px;
    padding: 10px 0 10px 65px;
    background: #1A1A1A;
    border: 1px solid #645845;
    border-radius: 4px;
    color: #F6CF8B;
}

.user input:focus {
    outline-style: none;
    border: 1px solid #E0D0B4 !important;
}

.input_fgx {
    width: 1px;
    height: 35px;
    background: #645845;
    opacity: 0.4;
    z-index: 1;
    position: relative;
    left: 60px;
    top: 5px;
}



.form_btn {
    background: linear-gradient(0deg, #A0814C, #D8B270);
    border-radius: 4px;
    padding: 12px 54px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #FFFFFF;
    transition: all 0.5s;
}
.btn_width{
    width: 90%;
}

.form_btn:hover {
    filter: brightness(1.2);
    color: #FFFFFF;
    transition: all 0.5s;
}

.login_btn {
    display: flex;
    justify-content: space-evenly;
}

.usercl {
    font-family: Source Han Sans SC;
    font-weight: 400;
    font-size: 12px;
    color: #cccccc;
}

.usercl a {
    color: #689bf6 !important;
}

.wx_login {
    margin-top: 10px;
    padding: 8px;
    width: 160px;
    height: 160px;
    background: #1A1A1A;
    border: 1px solid #897551;
    border-radius: 4px;
    position: relative;
}

// 遮罩
.zz2 {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8) !important;
    position: absolute;
    left: 0;
    top: 0;
}

.zz_title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.zz_title span {
    width: 100px;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
}

.plus {
    cursor: pointer;
    transition: all 0.5s;
}

.plus:hover {
    transition: all 0.5s;
    color: #B5945B !important;
}

.host_paw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2%;
    width: 90%;
    margin-left: 6%;
}

.gou {
    width: 16px;
    height: 16px;
    background: #FFFFFF;
    border: 1px solid #B5945B;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
}

.paw_title {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #B5945B;
    text-decoration: none;
}

@media (max-width:1100px) {
    .login_box>img {
        width: 63rem;
    }

    .input_title {
        font-size: 13px;
    }

    .input_title {
        font-size: 12px;
    }

    .input_fgx {
        height: 28px;
    }

    .form_btn {
        padding: 8px 42px;
        font-size: 12px;
    }

    .user input {
        width: 250px;
        padding: 5px 0 5px 60px;
    }

    .login_title {
        margin-left: 48%;
        width: 10rem;
    }

    .user label {
        font-size: 15px;
    }

    .wx_login {
        width: 130px;
        height: 130px;
    }

    ::v-deep #qrCode>img {
        width: 112px !important;
    }

    .gou {
        width: 12px;
        height: 12px;
    }

    .paw_title {
        font-size: 12px;

    }

    .host_paw {
        width: 85%;
        margin-left: 8%;
    }
}
</style>